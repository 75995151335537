.step-wizard-list {
  background: transparent;
  list-style-type: none;
  border-radius: 10px;
  display: flex;
  padding: 0px 10px;
  position: relative;
  z-index: 10;
}

.step-wizard-item {
  margin-top: 50px;
  padding: 0 10px;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  
  position: relative;
}

.step-wizard-item + .step-wizard-item:after {
  /* LINE */
  content: "";
  position: absolute;
  left: 0;
  top: 19px;
  background: #829098;
  width: 100%;
  height: 3px;
  transform: translateX(-50%);
  z-index: -10;
}


